@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background: #f4f4f5;
}

@layer base {
  html {
    @apply text-base;
    scroll-behavior: smooth;
  }
  body {
    @apply font-montserrat;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
